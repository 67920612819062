.video-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50px;
    right: 350px;
    align-items: flex-start;
}

.video-container > video {
    width: 1280px;
    height: 720px;
    background-color: black;
}

.video-player {
    width: 1280px;
    height: 720px;
    background-color: black;
}

.video-controls{
    position: absolute;
    top: 680px;
    left: 15px;
}

.video-caption {
    padding: 10px;
    display:inline-block;
    font-size: x-large;
}

.creator-icon{
    display: inline-block;
    width: 50px;
    height: 50px;
}

.creator-icon > img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin: 10px;
    cursor: pointer;
}