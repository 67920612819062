.cat-container {
    position: fixed;
    top: 0;
    right: 250px;
    display: flex;
    flex-direction: row;
    z-index: 1;
    width: 100vw;
    height: 50px;
    background-color: #00FF00;
}
.cat {
    display: inline-block;
    padding: 5px 7px;
    margin: 10px;
    font-size: medium;
    color: #FFFFFF;
    background-color: #000000;
    border-radius: 10px;
    cursor: pointer;
}