.listing-container{
    padding: 20px;
    position: relative;
    top: 50px;
    right: 250px;
    display: flex;
    height: calc(100vh - 50px);
    width: calc(100vw - 250px);
    justify-content: start;
    align-content: start;
    flex-wrap: wrap;
    background-color: #F5F5F5;
    overflow-y: auto;
}