li {
    list-style: none;
    display: block;
    font-size: x-large;
    padding: .5em;
    border-top: solid .5px #00FF00;
    width: 100%;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    padding: 0;
    position: relative;
    width: 100%;
}

.nav-bar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #007BFF;
    width: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #FFFFFF
}

.logo {
    font-size: xx-large;
    margin: 2em;
    display: block;
    color: #FFD700;
    font-weight: 1000;
    animation: Rotate-Logo 2s infinite linear;
    display: none;
}

.copyright{
    position: absolute;
    bottom: 10px;
}

@keyframes Rotate-Logo {
    from {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-5deg);
    }
}

.com {
    direction: ltr;
    font-size: small;
}