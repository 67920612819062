.tumbnail{
    display: block;
    position: relative;
    width: 300px;
}

.tumbnail > img {
    border-radius: 15px;
}

.caption{
    display: block;
    position: relative;
    font-size:medium;
    word-wrap: normal;
    color: #000000;
    width: 300px;
    direction: ltr;
}

.container {
    position: relative;
    display: inline-flex;
    margin: 10px;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
}